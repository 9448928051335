/* eslint-disable */
import { OrderCloudSearchProvider } from '@sitecore-search/react';
import { ThemeUpperCase } from 'src/helpers/Constants';
import { Configuration } from 'ordercloud-javascript-sdk';
import { ReactNode, useEffect } from 'react';
import { useOcSelector } from 'src/redux/ocStore';
import { requestDecorator } from 'src/utils/requestDecorator';
interface SitecoreOrderCloudSearchProvider {
  sitenameUppercase: ThemeUpperCase;
  children: ReactNode;
}

const SitecoreOrderCloudSearchProvider = ({
  sitenameUppercase,
  children,
}: SitecoreOrderCloudSearchProvider) => {
  let configArray: configArrayTypes = {
    // env: undefined,
    // customerKey: undefined,
    // apiKey: undefined,
    discoverDomainId: undefined,
    orderCloudRegion: undefined,
    searchEnv: undefined,
    searchApiKey: undefined,
    // publicSuffix: undefined,
  };

  interface configArrayTypes {
    // env: any;
    // customerKey: any;
    // apiKey: any;
    discoverDomainId: any;
    orderCloudRegion: any;
    searchEnv: any;
    searchApiKey: any;
    // publicSuffix: any;
  }

  switch (sitenameUppercase) {
    case 'PSP':
      configArray = {
        // env: process.env.NEXT_PUBLIC_PSP_SD_ENV || 'dev',
        // env: 'prod',
        // customerKey: process.env.NEXT_PUBLIC_PSP_SD_CUSTOMER_KEY || '141684790-81954192',
        // customerKey: '141684790-203148695',
        // // apiKey: process.env.NEXT_PUBLIC_PSP_SD_API_KEY || 'https://api.rfksrv.com',
        // apiKey: '01-be590ec1-dda79f2c3f6809f3e2bc19362245aa451431a17b',
        discoverDomainId: process.env.NEXT_PUBLIC_PSP_SD_ID || '',
        // discoverDomainId: 203148695,
        orderCloudRegion: process.env.NEXT_PUBLIC_PSP_OC_REGION || 'usEast',
        // orderCloudRegion: 'usEast',
        searchEnv: process.env.NEXT_PUBLIC_PSP_SD_ENV || 'prod',
        // searchEnv: 'prod',
        searchApiKey: process.env.NEXT_PUBLIC_PSP_SD_API_KEY || '',
        // searchApiKey: '01-c26fa01a-65d44862506eb90e8d4c04ee26baed689cf20b4d',
        // publicSuffix: process.env.NEXT_PUBLIC_PSP_SD_ID || '',
      };
      break;

    case 'WNW':
      configArray = {
        // // env: process.env.NEXT_PUBLIC_PSP_SD_ENV || 'dev',
        // env: 'prod',
        // customerKey: process.env.NEXT_PUBLIC_PSP_SD_CUSTOMER_KEY || '141684790-81954192',
        // customerKey: '141684790-203148695',
        // // apiKey: process.env.NEXT_PUBLIC_PSP_SD_API_KEY || 'https://api.rfksrv.com',
        // apiKey: '01-be590ec1-dda79f2c3f6809f3e2bc19362245aa451431a17b',
        discoverDomainId: process.env.NEXT_PUBLIC_WNW_SD_ID || '',
        // discoverDomainId: '203148695',
        orderCloudRegion: process.env.NEXT_PUBLIC_WNW_OC_REGION || 'usEast',
        // orderCloudRegion: 'usEast',
        searchEnv: process.env.NEXT_PUBLIC_WNW_SD_ENV || 'prod',
        // searchEnv: 'prod',
        searchApiKey: process.env.NEXT_PUBLIC_WNW_SD_API_KEY || '',
        // searchApiKey: '01-c26fa01a-65d44862506eb90e8d4c04ee26baed689cf20b4d',
        // publicSuffix: process.env.NEXT_PUBLIC_PSP_SD_ID || '',
        // env: process.env.NEXT_PUBLIC_WNW_SD_ENV || 'dev',
        // customerKey: process.env.NEXT_PUBLIC_WNW_SD_CUSTOMER_KEY || '',
        // apiKey: process.env.NEXT_PUBLIC_WNW_SD_API_KEY || 'https://api.rfksrv.com',
        // discoverDomainId: process.env.NEXT_PUBLIC_WNW_SD_ID || '',
        // orderCloudRegion: process.env.NEXT_PUBLIC_PSP_SD_ENV || '',
        // searchEnv: process.env.NEXT_PUBLIC_WNW_SD_ENV || '',
        // searchApiKey: process.env.NEXT_PUBLIC_WNW_SD_API_KEY || '',
        // // publicSuffix: process.env.NEXT_PUBLIC_WNW_SD_ID || '',
      };
      break;
    default:
      null;
      break;
  }

  // Move this to a useEffect so we can have a dependency that only calls
  // `Configuration.Set` when the site name changes (which should be never under normal circumstances)
  // We were seeing this was being called repeatedly.
  useEffect(() => {
    switch (sitenameUppercase) {
      case 'PSP':
        Configuration.Set({
          clientID: process.env.NEXT_PUBLIC_PSP_OC_CLIENT_ID,
          baseApiUrl: process.env.NEXT_PUBLIC_PSP_OC_BASE_API_URL,
        });
        break;

      case 'WNW':
        Configuration.Set({
          clientID: process.env.NEXT_PUBLIC_WNW_OC_CLIENT_ID,
          baseApiUrl: process.env.NEXT_PUBLIC_WNW_OC_BASE_API_URL,
        });
        break;
      default:
        null;
        break;
    }
  }, [sitenameUppercase]);

  // Fetch required data for maintaining SoftLogin state.
  const ocCurrentOrder = useOcSelector((s) => s.ocCurrentOrder);
  const ocUser = useOcSelector((s) => s.ocUser);

  return (
    <OrderCloudSearchProvider
      {...configArray}
      requestMiddleware={() => requestDecorator(ocUser.user, ocCurrentOrder)}
    >
      {children}
    </OrderCloudSearchProvider>
  );
};

export default SitecoreOrderCloudSearchProvider;
